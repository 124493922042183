<template>
  <base-material-card
    color="primary"
    icon="mdi-table-large"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">Campos de Linhas</div>
    </template>
    <v-container
      fluid
      class="py-0"
    >
      <v-row
        align="center"
        justify="start"
        class="mt-2"
      >
        <draggable
          v-bind="dragOptions"
          v-model="camposSelecionados"
          handle="#dragHandle"
        >
          <div
            v-for="(campoSelecionado, i) in camposSelecionados"
            :key="campoSelecionado.column"
            class="d-inline-flex shrink ma-1"
          >
            <v-chip
              :disabled="loading"
              color="grey lighten-3"
              close
              @click:close="camposSelecionados.splice(i, 1)"
            >
              <div class="d-inline-flex">
                <v-icon
                  id="dragHandle"
                  class="mr-1"
                  style="cursor: move"
                >
                  mdi-reorder-horizontal
                </v-icon>
                {{ campoSelecionado.column | toUpperCase }}
              </div>
            </v-chip>
          </div>
        </draggable>
      </v-row>
      <v-row class="mt-n5">
        <v-col
          md="5"
          cols="12"
        >
          <v-text-field
            ref="search"
            class="ml-n2"
            v-model="search"
            hide-details
            prepend-icon="mdi-magnify"
            label="Pesquisar campo"
            single-line
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="6"
          cols="12"
          class="ml-n3 mt-n5"
        >
          <v-checkbox
            v-model="todosCheckbox"
            @change="camposSelecionados = []"
            v-if="selecionarTodos"
          >
            <template v-slot:label>
              <span class="black--text">Selecionar todos os campos</span>
            </template>
          </v-checkbox>
          <div v-else>&nbsp;</div>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="transitioncard mt-n5"
      :style="{ overflow: 'hidden', 'max-height': chipContainerHeight }"
    >
      <template v-for="campo in camposFiltrados">
        <v-chip
          outlined
          class="mr-1 mt-1"
          v-if="!camposSelecionados.includes(campo)"
          :key="campo.key"
          :disabled="loading || todosCheckbox"
          @click="camposSelecionados.push(campo)"
        >
          {{ campo.column | toUpperCase }}
        </v-chip>
      </template>
    </div>
    <div
      justify="center"
      align="center"
      class="mt-n3"
    >
      <v-btn
        v-if="chipContainerHeight != '100%' && campos.length > 0"
        class="mt-2 mb-n3 ml-n7"
        icon
        color="gray"
        @click="chipContainerHeight = '100%'"
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn
        v-else-if="campos.length > 0"
        class="mt-2 mb-n3 ml-n7"
        icon
        color="gray"
        @click="chipContainerHeight = '37px'"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>
  </base-material-card>
</template>

<script>
export default {
  components: {
    draggable: () => import('vuedraggable')
  },

  props: {
    campos: {
      type: Array,
      default: () => []
    },
    relatorio: {
      type: Object,
      default: () => {}
    },
    selecionarTodos: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    loading: false,
    search: '',
    camposSelecionados: [],
    todosCheckbox: false,
    chipContainerHeight: '37px',
    dragOptions: { animation: 250, ghostClass: 'ghost' }
  }),

  computed: {
    camposFiltrados() {
      const search = this.search.toLowerCase();
      if (!search) return this.campos;

      return this.campos.filter((campo) =>
        campo.column.toLowerCase().includes(search)
      );
    }
  },

  methods: {
    exportCampos() {
      if (this.todosCheckbox) {
        return JSON.parse(JSON.stringify(this.campos));
      } else {
        return JSON.parse(JSON.stringify(this.camposSelecionados));
      }
    },

    getRelatorioCampos() {
      const campos = JSON.parse(this.relatorio.estrutura_json).campos.filter(
        (campo) => !['SUM', 'AVG', 'MIN', 'MAX', 'COUNT'].includes(campo.funcao)
      );
      let selecionados = [];

      campos.forEach((campo) => {
        this.campos.forEach((item) => {
          if (campo.campo == item.column) selecionados.push(item);
        });
      });

      if (
        selecionados.length === this.campos.length &&
        selecionados.every((value, index) => value === this.campos[index])
      ) {
        this.todosCheckbox = true;
      } else {
        this.camposSelecionados = selecionados;
      }
    }
  },

  watch: {
    campos() {
      this.camposSelecionados = [];
      this.todosCheckbox = false;

      if (Object.keys(this.relatorio).length !== 0) this.getRelatorioCampos();
    }
  }
};
</script>
